import React from 'react'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { PageLayout } from '../components/PageLayout'
import { Hero } from '../components/Hero'
import { CompetitorReview } from '../components/CompetitorReview'
import { PageBlock } from '../components/PageBlock'

export const Head = () => (
    <>
        <title>Library Websites Survey | Orientier Proposal Hub for WPL Digital Branch</title>
        <meta name="robots" content="noindex" />
    </>
)

const hero = (
    <Hero
        heading="Surveying the Landscape of Public Library Websites"
        subheading="A review of other library sites and their relevance to this project"
    />
)

const libraries = [
    {
        name: 'Lucius Beebe Memorial Library',
        location: 'Wakefield, Massachusetts',
        url: 'https://www.wakefieldlibrary.org',
        imageDir: 'wakefield',
        pros: [
            'Bright aesthetic',
            "Lead with hero image showcasing the library's inviting physical space",
            'Unique, playful-but-classy logo',
            'Sub-header bar that shrinks slightly to conserve space as you scroll',
            'Quick Links menu with iconography',
            '"Building a Better Wakefield" section that stands out on homepage and links history with current initiatives',
            'Use of bee/hive motif, e.g. in the "Join the Hive…" newsletter call-to-action near the bottom of the homepage',
            'New Arrivals and other book list components',
            'Generally good responsive design (on various screen sizes)',
        ],
        cons: [
            'You must scroll all the way back to the top of the page to access sitewide navigation',
            'Minimal footer navigation',
            'Some layout quirks',
            'Teaser images/thumbnails are often cropped in a poor way (TBD whether admins can customize the cropping per item in the CMS when the default is not desirable)',
        ],
        notes: [
            'Book lists (e.g. "New Arrivals") use imagery from, and link directly to, a NOBLE catalog entry (Evergreen-based). Hopefully website admins can assemble these lists natively in the CMS via ILS integration. Allowing patrons to quickly access the actual catalog entry is good, but it would also be nice to have the option to elaborate on what makes each book noteworthy (themed per website aesthetic) before sending users to the catalog.',
            'The "Niche Academy" widget offers an intro and links to various resources. The widget has been styled to match the website theme, although the sticky tab at the right side of screen is not optimally positioned.',
        ],
        platform: ['Wordpress'],
    },
    {
        name: 'Haverhill Public Library',
        location: 'Haverhill, Massachusetts',
        url: 'https://haverhillpl.org',
        imageDir: 'haverhill',
        pros: [
            'Attractive hero area with single point of focus',
            'Generally good responsive design (on various screen sizes)',
            'Use of playful alignment and animation for button links (stylistically subjective, but makes the site feel more engaging)',
            'Generally sensible structure/navigation',
            'Good use of logo and wordmark',
            'Sticky header',
            'Attractive event promos/teasers',
            'Book reviews',
            '"Similar Books" components',
        ],
        cons: [
            'Carousel in hero area can be problematic',
            'Some images not adapted/optimized for mobile screens',
            'Sticky header could be hidden when less likely to be sought after',
            'Event promos/teasers are not synced with Eventkeeper (e.g. the "Hellstrip Gardening" event has a 2022 date in the CMS whereas the Eventkeeper event has the correct 2023 date).',
        ],
        notes: [
            'Users can toggle between English and Spanish languages, though some content, e.g. book review text, is not translated into Spanish.',
        ],
        platform: ['Wordpress'],
    },
    {
        name: 'New York Public Library',
        location: 'New York, New York',
        url: 'https://www.nypl.org',
        imageDir: 'nypl',
        pros: [
            'Good variety of page blocks/components/widgets',
            'Clean design with good use of red secondary color and line motif',
            'Generally good responsive design (on various screen sizes)',
            'Users can search the catalog without appearing to leave the NYPL site',
            'Good use of original imagery (e.g. library card promo)',
            'Sitewide alert/announcement pane',
            'Ability to explore blog entries by "channel"',
            'iOS and Android mobile apps, promoted on the website in a handful of top languages',
            'Good range of services available via app (self-checkout, catalog and ISBN scan, account management, e-resources, etc.)',
            '...and more',
        ],
        cons: [
            '"Get Email Updates" main menu item appears to be a dropdown but is not',
            'Must scroll to top or bottom of page to access navigation',
            'Some app features are disjointed. Integration with website/ILS/etc. is absent in places and could be improved in others.',
            '...and more',
        ],
        notes: [
            'There is a "Language" page with catalog links for numerous languages and per-language resource pages. Some pages, e.g. library card request form page, have been translated into multiple languages. However, full-site translation appears to be missing/WIP',
            'The book review block with quotes is very compelling; however, it would benefit from some rules for managing longer quotes (note that the initial text for "The Escape Artist" quote is cut off).',
            'Larger than WPL but more in line with the features WPL is seeking.',
        ],
        platform: ['Drupal'],
    },
]

const ReviewPage = ({ data }) => {
    const images = {}
    data?.allFile?.group?.forEach((group) => {
        images[group?.fieldValue] = group?.edges
    })

    return (
        <PageLayout hero={hero}>
            <PageBlock variant="minimal">
                <Flex flexDir="column" alignItems="space-between" gap={8}>
                    {libraries &&
                        libraries.map((library, index) => {
                            const libraryImages =
                                typeof images[`libraries/${library?.imageDir}`] !== 'undefined'
                                    ? images[`libraries/${library?.imageDir}`]
                                    : []
                            const { imageDir, ...extraProps } = library
                            return (
                                <>
                                    <CompetitorReview key={index} images={libraryImages} {...extraProps} />
                                    {index < libraries.length - 1 && (
                                        <Text
                                            textAlign="center"
                                            color="oriTeal.200"
                                            fontSize="9xl"
                                            lineHeight="none"
                                            fontFamily="serif"
                                        >
                                            ~
                                        </Text>
                                    )}
                                </>
                            )
                        })}
                </Flex>
            </PageBlock>
        </PageLayout>
    )
}

export default ReviewPage

export const pageQuery = graphql`
    query {
        allFile(
            filter: { relativeDirectory: { regex: "/libraries/.+/" } }
            sort: { fields: [relativeDirectory, name], order: ASC }
        ) {
            group(field: relativeDirectory) {
                field
                fieldValue
                totalCount
                edges {
                    imgThumb: node {
                        childImageSharp {
                            gatsbyImageData(width: 100, height: 100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                    imgPrimary: node {
                        childImageSharp {
                            gatsbyImageData(width: 480, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                    imgMedium: node {
                        childImageSharp {
                            gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                }
            }
        }
    }
`
